import { Component, OnInit } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EventsService } from 'src/app/services/events/events.service';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { HttpService } from 'src/app/services/http.service';
import { AccountComponent } from '../account/account.component';
import { NotificationsComponent } from '../notifications/notifications.component';
// import 'src/assets/vendors/countup/countUp.umd';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  is_logged = false;
  sub1: Subscription;
  countAlert = 0;
  icon_inactive_help: any = "https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fui%2Ficons%2Fquestions-inactive.svg?alt=media&token=b06fd462-a6e2-4e72-ad5e-94cf549d74c7";
  icon_active_help: any   = "https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fui%2Ficons%2Fquestions-active.svg?alt=media&token=7dd305f9-b304-4d9c-bee5-21aebbc6ffa3";
  activeIcon_help: any    = "";
  icon_active_notifications: any    = "https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fui%2Ficons%2Fnotification-active.svg?alt=media&token=4c596f4a-91ea-471b-8a2b-6e446cb1ef9a";
  icon_inactive_notifications: any  = "https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fui%2Ficons%2Fnotification-inactive.svg?alt=media&token=3e3a1b43-410e-448e-b8a2-57ba99bcb76e";
  activeIcon_notifications: any     = "";
  icon_active_account: any = "https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fui%2Ficons%2Fuser-account-active.svg?alt=media&token=01fc197f-a9ab-40c6-aa2a-2c9d8375f826";
  icon_inactive_account: any = "https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fui%2Ficons%2Fuser-account-inactive.svg?alt=media&token=bcf0abde-661a-45e3-afc2-f6dbde3d5dbc";
  activeIcon_account: any = "";
  sidebar: boolean = true;
  user = new User();

  orders: any [] = [];
  sub: Subscription;
  collectionName = 'Orders';
  collectionField = 'order_createday';
  orderByDir: any = 'desc';
  show_animation: boolean = false;
  // ADDED BY LEO 07-10-2022
  settings: any;
  appName: any;
  RegisterScreen: any;
  imagen: any;
  boxNotification: any;
  primary = '';

  constructor(
    private menuController: MenuController,
    private popoverController: PopoverController,
    private auth: AuthService,
    private firestore: FirestoreService,
    private service: HttpService,
    private events: EventsService,
    private account: AccountComponent
  ) {
    this.activeIcon_help = this.icon_inactive_help;
    this.activeIcon_notifications = this.icon_inactive_notifications;
    this.activeIcon_account = this.icon_inactive_account;
    // ADDED BY LEO 07-10-2022
    this.service.sendGET_Settings().subscribe((data) => {
      this.settings  = data.filter( item => item.id == "General");
      this.RegisterScreen = data.filter( item => item.id == "RegisterScreen");
      console.log("RegisterScreen: ",this.RegisterScreen);
      this.appName      = this.settings[0].appName;
      this.imagen       = this.RegisterScreen[0].register_screen_commerce_logo;
      this.primary       = this.RegisterScreen[0].register_screen_commerce_logo;
      this.getdatatables().then( res => {
        console.log("header: ",res);
      });
    });
  }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.auth.getUser2().then(async userLocal => {
      console.log("User: ",userLocal);
      this.user = userLocal;
    }).catch(async (e) => {
    })
  }

  getdatatables(){
    return new Promise((resolve, reject) => {
      if (this.sub) { this.sub.unsubscribe(); }
      this.sub = this.firestore.search(this.collectionName, {
        'manager_user_uid': "",
        'orderByName': this.collectionField,
        'orderByDir': this.orderByDir,
      }).subscribe(res => {
        this.service.sendGET_Orders().subscribe((data) => {
          // console.log("sendGET_Orders: ",data);
          // console.log("New: ",data[i])
          // var sound = localStorage.getItem('alert_sound');
            console.log("settings: ",this.settings);

            if (data.length > 0) {
              let news = data.filter(item => item['orders_status_uid'] == 'New');
              this.orders = news;
              if (news.length > 0) {
                if (this.settings[0].alert_new_order_sound === true) {
                  this.events.publish('alert:sound', { from: 'header', sound: true });
                }
                this.show_animation = true;
              } else {
                if (this.settings[0].alert_new_order_sound === true) {
                  this.events.publish('alert:sound', { from: 'header', sound: false });
                }
                this.orders.length = 0;
                this.show_animation = false;
              }
            }
            resolve(this.orders);
        });
      })
    })
  }

  alert() {
    console.log('alert publish');
    this.events.publish('alert:sound', { from: 'header', sound: false });
  }

  async closeMenu() {
    this.menuController.isEnabled('menu-content').then(status => {
      if(status){
        console.log(this.menuController.isEnabled('menu-content'));
        this.menuController.enable(false,'menu-content');
        this.menuController.enable(true,'icon-menu');
      }else{
        console.log("main menu disabled");
        this.menuController.enable(true,'menu-content');
        this.menuController.enable(false,'icon-menu');
      }
    });
  }

  async openMenu() {
    if (await this.menuController.isEnabled() === true) {
      this.sidebar = true;
      this.menuController.enable(false);
      this.menuController.close();
    }else{
      this.sidebar = false;
      console.log("menu open");
      this.menuController.enable(true);
      this.menuController.open();
    }
  }

  buttonsIcons(){
    if (this.activeIcon_help === this.icon_active_help) {
      this.activeIcon_help = this.icon_inactive_help;
    }else{
      this.activeIcon_help = this.icon_active_help;
    }
    this.activeIcon_notifications = this.icon_inactive_notifications;
    this.activeIcon_account = this.icon_inactive_account;

  }

  async presentPopover(ev: any, popovernav) {
    let component;
    let classcomponent;

    if (popovernav === 'notifications') {
      component = NotificationsComponent;
      classcomponent = 'class-component-notification';
      this.activeIcon_notifications = this.icon_active_notifications;
      this.activeIcon_account = this.icon_inactive_account;
      this.activeIcon_help = this.icon_inactive_help;
      this.alert();
    }else if (popovernav === 'account') {
      component = AccountComponent;
      classcomponent = 'class-component-account';
      this.activeIcon_account = this.icon_active_account;
      this.activeIcon_notifications = this.icon_inactive_notifications;
      this.activeIcon_help = this.icon_inactive_help;

    }

    const popover = await this.popoverController.create({
      component,
      cssClass: classcomponent,
      event: ev,
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  signOut(){
    this.account.askToSignOut();
  }
}

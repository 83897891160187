import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore/firestore.service';
import { HttpService } from 'src/app/services/http.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  orders: any [] = [];
  sub: Subscription;
  collectionName = 'Orders';
  collectionField = 'order_createday';
  orderByDir: any = 'desc';

  constructor(
    private firestore: FirestoreService,
    private service: HttpService,
  ) { }

  ngOnInit() {
    this.getdatatables().then( res => {
      console.log("notifications: ",res);
    });
  }

  ionViewWillEnter(){
  }

  getdatatables(){
    return new Promise((resolve, reject) => {
      if (this.sub) { this.sub.unsubscribe(); }
      this.sub = this.firestore.search(this.collectionName, {
        'manager_user_uid': "",
        'orderByName': this.collectionField,
        'orderByDir': this.orderByDir,
      }).subscribe(res => {
        this.service.sendGET_Orders().subscribe((data) => {
          // console.log("sendGET_Orders: ",data);
            this.orders = [];
            for (let i = 0; i < data.length; i++) {
              // console.log("New: ",data[i])
              if (data[i].created_at_formatted) {
                if (data[i].orders_status_uid === "New") {
                  var all = data[i].created_at_formatted.split(" ");
                  var timesplit = all[6].split(":");
                  var date = new Date(all[1]+all[3]+all[5]);
                  var time;
                  if (timesplit[0] > 11) {
                    time = all[6] + " PM";
                  }else{
                    time = all[6] + " AM";
                  }
                  const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
                  const formatDate = (date)=>{
                    let formatted_date;
                    if (parseInt(date.getDate()) > 9) {
                      formatted_date = months[date.getMonth()] + " " + date.getDate() + "/" + date.getFullYear()
                    }else{
                      formatted_date = months[date.getMonth()] + " 0" + date.getDate() + "/" + date.getFullYear()

                    }
                    return formatted_date;
                  }

                  this.orders.push({
                    id: data[i].id,
                    uid: data[i].uid,
                    who_receives: data[i].who_receives,
                    total: data[i].total,
                    sucursal: data[i].sucursal,
                    created_at_formatted: formatDate(date),
                    order_createday: data[i].order_createday,
                    badgeColor: data[i].badgeColor,
                    orders_status_uid: data[i].orders_status_uid,
                  });

                  this.orders.sort(function (a, b) {
                    if (a.order_createday._seconds < b.order_createday._seconds) {
                      return 1;
                    }
                    if (a.order_createday._seconds > b.order_createday._seconds) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  });
                }
              }
            }
            var numeric = 1;

            this.orders.forEach((element, index) => {
                  this.orders[index].numeric = numeric++;
            });
            resolve(this.orders);
        });
      })
    })
  }

  alertNotification(){
    return  this.orders;
  }

  ToFixed(number:number){
    return number.toFixed(2);
  }

}

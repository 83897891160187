import {Component} from '@angular/core';
import {HttpService} from './services/http.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {MenuController, Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {map, mergeMap} from 'rxjs/internal/operators';
import { AuthService, EventsService, NotificationService} from './services';
import { User } from './models/user';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger('inOut', [
      transition(':enter', [
        style({transition: '1s'}),
        animate(100, style({opacity: 0, transition: '1s'})),
        animate(500, style({opacity: 1, maxHeight: 'auto'}))         // final style after the transition has finished
      ]),
      transition(':leave', [
        animate(500, style({opacity: 0}))          // we asume the initial style will be always opacity: 1
      ])
    ])
  ]
})
export class AppComponent {
  appPages: any [] = [];
  appPages2: any [] = [];
  imagen: any;
  imagen_sm: any;
  showMain1 = false;
  isLogged = false;
  email = '';
  photoURL = '';
  is_super = false;
  breadcrumbs;
  isLogout: boolean = false;
  isRemainder = 1;
  isRemainder2 = 1;
  isRemainder3 = 1;
  show_items: boolean = false;
  settings: any;
  appName: string;
  isRestaurant: boolean = false;
  isRetail: boolean = false;
  user = new User();
  is_admin: boolean = false;

  constructor(
    private service: HttpService,
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private menuController: MenuController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public notification: NotificationService,
    public events: EventsService,
    private auth: AuthService,
  ) {
    // ADDED BY LEO 07-10-2022
    this.service.sendGET_Settings().subscribe((data) => {
      [this.settings] = data;
      data.forEach(element => {
        if (element.id === "General") {
          this.appName      = element.appName;
          this.isRestaurant = element.isRestaurant;
          this.isRetail     = element.isRetail;
        }
      });
    });
    events.subscribe('alert:global', (data) => {
      console.log('event alert:global' + data)
    });
    this.initializeApp();
    this.service.sendGET_SettingsApp().subscribe((data) => {
      // console.log(JSON.stringify(data));
      this.imagen = data.register_screen_commerce_logo;
      this.imagen_sm = data.register_screen_commerce_logo_sm;
    });

    this.service.sendGET_Menu().subscribe((data) => {
      console.log("sendGET_Menu: ", data);
      for (let i = 0; i < data.length; i++) {
        // if  (data[i].onlyRestaurant && !this.isRestaurant) continue;
        console.log(data[i].option_icon);
        if (data[i].option_menu_active){
          this.appPages.push({
            id: data[i].id,
            numeric: data[i].option_menu_order,
            title: data[i].option_menu_name,
            url: '/' + data[i].option_menu_url,
            icon_active: data[i].option_menu_icon_active,
            icon_inactive: data[i].option_menu_icon_inactive,
            icon: data[i].option_icon,
            active: data[i].option_menu_active,
            onlyRestaurant: data[i].onlyRestaurant,
            onlyRetail: data[i].onlyRetail,
          });
        }
      }

      this.appPages.sort(function (a, b) {
        if (a.numeric > b.numeric) {
          return 1;
        }
        if (a.numeric < b.numeric) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    });

    this.menuController.enable(true, 'menu-content');
    this.menuController.enable(false, 'icon-menu');

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {

        let snapshot = this.router.routerState.snapshot;
        this.breadcrumbs = [];
        let url = snapshot.url;
        let routeData = route.snapshot.data;

        let label = routeData['breadcrumb'];
        let params = snapshot.root.params;

        this.breadcrumbs.push({
          url: url,
          label: label,
          params: params
        });

      });
  }

  ngOnInit() {
    this.auth.getUser2().then(async userLocal => {
      console.log("ngOnInit AppComponent: ",userLocal);
    })
  }

  iconShow(index) {
    // this.appPages.forEach((element,index) => {
    //   this.appPages[index].icon = this.appPages[index].icon_inactive;
    // });
    // this.appPages[index].icon = this.appPages[index].icon_active;
  }

  loadRoles(user,page){
    return new Promise(async (resolve, reject) => {
      this.service.sendGET_OneSettingsApp("Roles").subscribe((data) => {
        this.appPages.length = 0;
        // console.log("sendGET_OneSettingsApp Roles: ",data);
        data.admin_view.forEach(views_user => {
          if (views_user.user_uid === user.uid && views_user.permit === true) {
            // console.log("admin_view: ",views_user);
            this.is_admin = true;
            resolve({
              is_admin: true,
              views_user
            });
          }
        });
        if (this.is_admin === true) {
          this.appPages2.forEach(element => {
            this.appPages.push(element);
          });
        } else {
          console.log("Page: ",page);
          var datos = data.views.filter(views => views.view_name === page);
          datos.forEach(views => {
            views.users.forEach(views_user => {
              if (views_user.user_uid === user.uid) {
                if (views_user.permit === true) {
                  if (views.view_name === page) {
                    resolve({
                      view_name: views.view_name,
                      view_uid: views.view_uid,
                      views_user
                    });
                  }
                }else{
                  resolve(false);
                }
              }else{
                resolve(false);
              }
            });
          });
        }
      });
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
    })
      .catch(() => {
      });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {CameraOptions} from "@awesome-cordova-plugins/camera/ngx";


export const environment = {
  production: false,
  project: "New Rolling",

  // PARAMETROS ADMIN - IANOS
  // firebase: {
  //   apiKey: "AIzaSyDPw9J-pflR8khJ4PeFp-owHu-gUveYZLs",
  //   authDomain: "ianos-platform-dev.firebaseapp.com",
  //   databaseURL: "https://ianos-platform-dev-default-rtdb.firebaseio.com",
  //   projectId: "ianos-platform-dev",
  //   storageBucket: "ianos-platform-dev.appspot.com",
  //   messagingSenderId: "453907306516",
  //   appId: "1:453907306516:web:35d0a4df6afece46d82f01",
  //   measurementId: "G-1TY39ZT2WS"
  // },
  // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  // urlBase: 'https://us-central1-ianos-platform-dev.cloudfunctions.net',
  // apikey: '6a4747e52f7-0428b4969d52d-3fb2-f509dd16',

  // PARAMETROS ADMIN - MIMA'S
  // firebase: {
  //   apiKey: "AIzaSyBP29SgRFwlpU9Fwwdc1OI4hbwpE-aAbXg",
  //   authDomain: "mimas-fire.firebaseapp.com",
  //   projectId: "mimas-fire",
  //   storageBucket: "mimas-fire.appspot.com",
  //   messagingSenderId: "624213884632",
  //   appId: "1:624213884632:web:b3fbd9486cea95e5c621ad"
  // },
  // googleMapsKey: 'AIzaSyC0N_UyDvlLly4CbXV3zOrGwgvmZIIWl_4',
  // urlBase: 'https://us-central1-mimas-fire.cloudfunctions.net',
  // apikey: '437fa6bf05e-4d7cce784420c-4cda-1bbcc655',

  // PARAMETROS CH
  // firebase: {
  //   apiKey: "AIzaSyA6Ri30hxwSGkopJkPXDpirF2cyiorslaU",
  //   authDomain: "ch-tienda-online-app-dev.firebaseapp.com",
  //   projectId: "ch-tienda-online-app-dev",
  //   storageBucket: "ch-tienda-online-app-dev.appspot.com",
  //   messagingSenderId: "543665834717",
  //   appId: "1:543665834717:web:d72a45b2950328f9236934",
  //   measurementId: "G-E9NVECM858"
  // },
  // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  // urlBase: 'https://us-central1-ch-tienda-online-app-dev.cloudfunctions.net',
  // apikey: 'c159bde8e57-6c1149f12ee44-418c-eee56df2',

  // PARAMETROS Plaza Mexico
  // firebase: {
  //   apiKey: "AIzaSyA_4GckamYTz5EwmjveV-ZzlRlQs9k1Hvs",
  //   authDomain: "ianos-app-plaza-mexico-pro.firebaseapp.com",
  //   projectId: "ianos-app-plaza-mexico-pro",
  //   storageBucket: "ianos-app-plaza-mexico-pro.appspot.com",
  //   messagingSenderId: "37269730606",
  //   appId: "1:37269730606:web:198ff19202b2b9f53c36bf",
  //   measurementId: "G-7Q7SWGE4WC"
  // },

  // urlBase: 'https://us-central1-ianos-app-plaza-mexico-pro.cloudfunctions.net',
  // apikey: '7c038b4dbe9-cb0efb6ad2429-4625-a44bca53',
  // googleMapsKey: 'AIzaSyAhh-_juFuiyhGe7aC0wQjlUS1fpXfJOaM',

  // // PARAMETROS NEW ROLLING DEV
  firebase: {
    apiKey: "AIzaSyACt8BEF8zwh49ErWHvj14ZdKwL-mqlLsE",
    authDomain: "ianos-newrolling-dev.firebaseapp.com",
    projectId: "ianos-newrolling-dev",
    storageBucket: "ianos-newrolling-dev.appspot.com",
    messagingSenderId: "1059308592105",
    appId: "1:1059308592105:web:0aba5eef8bf6f7cd100978",
    measurementId: "G-M90M91QVE7"
  },

  googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',
  urlBase: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net',
  urlBaseWompy: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net/Wompi',
  urlBaseNR: 'https://api.newrolling.com',
  apikey: 'c68790b2244-50a832abf6f6e-4f4c-606dc078',

  // PARAMETROS NEW ROLLING PRO
  // firebase: {
  //   apiKey: "AIzaSyBgmFSjwQsnXaWREGx6wKIJ23c5_qhCeqI",
  //   authDomain: "newrolling-pro.firebaseapp.com",
  //   projectId: "newrolling-pro",
  //   storageBucket: "newrolling-pro.appspot.com",
  //   messagingSenderId: "883404852630",
  //   appId: "1:883404852630:web:c9496b6151d0689e0861b7",
  //   measurementId: "G-8E9QPRPXFE"
  // },

  // urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
  // urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
  // urlBaseNR: 'https://api.newrolling.com',
  // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',
  // googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

};

// Camera Configuration
// Please refer to the official Cordova Plugin Camera docs at: https://github.com/apache/cordova-plugin-camera#module_camera.CameraOptions
export const cameraOptions: CameraOptions = {
  allowEdit: true,
  correctOrientation: true,
  // DATA_URL : 0,   Return image as base64-encoded string (DATA_URL can be very memory intensive and cause app crashes or out of memory errors. Use FILE_URI or NATIVE_URI if possible),
  // FILE_URI : 1,   Return image file URI,
  // NATIVE_URI : 2  Return image native URI
  destinationType: 1,
  // JPEG = 0,
  // PNG = 1
  encodingType: 0,
  // PICTURE = 0,
  // VIDEO = 1,
  // ALLMEDIA = 2
  mediaType: 0,
  quality: 25,
  targetHeight: 512,
  targetWidth: 512
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class Countrys {
  countrys = [
    {
      "name_en": "Afghanistan",
      "name_es": "Afganistán",
      "dial_code": "+93",
      "code": "AF"
    },
    {
      "name_en": "Albania",
      "name_es": "Albania",
      "dial_code": "+355",
      "code": "AL"
    },
    {
      "name_en": "Algeria",
      "name_es": "Argelia",
      "dial_code": "+213",
      "code": "DZ"
    },
    {
      "name_en": "AmericanSamoa",
      "name_es": "Samoa Americana",
      "dial_code": "+1684",
      "code": "AS"
    },
    {
      "name_en": "Andorra",
      "name_es": "Andorra",
      "dial_code": "+376",
      "code": "AD"
    },
    {
      "name_en": "Angola",
      "name_es": "Angola",
      "dial_code": "+244",
      "code": "AO"
    },
    {
      "name_en": "Anguilla",
      "name_es": "Anguilla",
      "dial_code": "+1264",
      "code": "AI"
    },
    {
      "name_en": "Antarctica",
      "name_es": "Antártida",
      "dial_code": "+672",
      "code": "AQ"
    },
    {
      "name_en": "Antigua and Barbuda",
      "name_es": "Antigua y Barbuda",
      "dial_code": "+1268",
      "code": "AG"
    },
    {
      "name_en": "Argentina",
      "name_es": "Argentina",
      "dial_code": "+54",
      "code": "AR"
    },
    {
      "name_en": "Armenia",
      "name_es": "Armenia",
      "dial_code": "+374",
      "code": "AM"
    },
    {
      "name_en": "Aruba",
      "name_es": "Aruba",
      "dial_code": "+297",
      "code": "AW"
    },
    {
      "name_en": "Australia",
      "name_es": "Australia",
      "dial_code": "+61",
      "code": "AU"
    },
    {
      "name_en": "Austria",
      "name_es": "Austria",
      "dial_code": "+43",
      "code": "AT"
    },
    {
      "name_en": "Azerbaijan",
      "name_es": "Azerbaiyán",
      "dial_code": "+994",
      "code": "AZ"
    },
    {
      "name_en": "Bahamas",
      "name_es": "Bahamas",
      "dial_code": "+1242",
      "code": "BS"
    },
    {
      "name_en": "Bahrain",
      "name_es": "Baréin",
      "dial_code": "+973",
      "code": "BH"
    },
    {
      "name_en": "Bangladesh",
      "name_es": "Banglades",
      "dial_code": "+880",
      "code": "BD"
    },
    {
      "name_en": "Barbados",
      "name_es": "Barbados",
      "dial_code": "+1246",
      "code": "BB"
    },
    {
      "name_en": "Belarus",
      "name_es": "Bielorrusia",
      "dial_code": "+375",
      "code": "BY"
    },
    {
      "name_en": "Belgium",
      "name_es": "Bélgica",
      "dial_code": "+32",
      "code": "BE"
    },
    {
      "name_en": "Belize",
      "name_es": "Belice",
      "dial_code": "+501",
      "code": "BZ"
    }
  ];
}

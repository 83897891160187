import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { EventsService } from '../events/events.service';
import { FirestoreService } from '../firestore/firestore.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new User();
  fbSubscription:Subscription;
  fsSubscription: Subscription;
  modelWatcher = new BehaviorSubject(new User());
  count:number = 0;
  constructor(
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    private storage: StorageService,
    private events: EventsService,
  ) { }

  // async getUser(val1): Promise<User> {
  //   this.count += 1;
  //   console.log('getUser() : ' + this.count + ' from ' + val1)
  //   return new Promise((resolve, reject) => {
  //     if (this.modelWatcher.value.uid != '') {
  //       console.log('auth getUserLocal() from ' + val1);
  //       console.log('auth from ' + val1+' '+this.modelWatcher.value);
  //       resolve(this.modelWatcher.value)
  //     } else {
  //       if (this.fbSubscription) { this.fbSubscription.unsubscribe(); }
  //       this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
  //         if (userFirebase) {
  //           console.log('auth getUserFirebase() from ' + val1);
  //           this.firestore.get('Users/' + userFirebase.uid).then(ref => {
  //             if (this.fsSubscription) { this.fsSubscription.unsubscribe(); }
  //             this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
  //               if (this.fsSubscription) { this.fsSubscription.unsubscribe(); }
  //                 console.log(userFirebase);
  //                 console.log(userLocal);
  //               if (userFirebase.emailVerified == true && userLocal.emailVerified == false) {
  //                 userLocal.emailVerified = true;
  //                 this.updateEmailVerified(userLocal);
  //               }
  //               console.log('authsync : getUserFirestore(): ' + this.count + 'from ' + val1);
  //               this.setUser(userLocal).then((res2) => {
  //                 resolve(userLocal);
  //               }), e => { console.log(e); reject(e); };
  //             }), e => { console.log(e); reject(e); };
  //           }).catch((e) => { console.log(e); reject(e); });
  //         } else { reject(); /* this.logout() */; }
  //       }), e => { console.log(e); reject(e); };
  //     }
  //   });
  // }

  setUser(val1) {
    return new Promise((resolve, reject) => {
      this.modelWatcher.next(val1);
      resolve(true);
    });
  }

  public updateEmailVerified(user): Promise<any> {
    return new Promise((resolve, reject) => {
      this.firestore.save('Users', user).then(res => {
        console.log('updateEmailVerified() true');
        resolve(res);
      }).catch(err => {
        console.log('updateEmailVerified() false');
        reject(err);
      });
    });
  }


  public getUser2(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.user.uid !== "") {
        // console.log('auth getUserLocal() : ', this.user);
        resolve(this.user)
      } else {
        if (this.fbSubscription) { this.fbSubscription.unsubscribe(); }
        this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
          if (userFirebase) {
            // console.log('auth getUserFirebase() : ', userFirebase);
            this.firestore.get('Users/' + userFirebase.uid).then(ref => {
              // console.log('auth get() : ', ref);

              if (this.fsSubscription) { this.fsSubscription.unsubscribe(); }
              this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
                this.user = userLocal;
                // console.log('auth getUserFirestore() : ', userLocal);
                resolve(userLocal);
              });
            }).catch((e) => {
              reject(e);
            });
          } else {
            reject(false);
          }
        });
      }
    });
  }

  public registerWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(res => {
        // console.log('1 - createUserWithEmailAndPassword res: ', res);
        resolve(res);
        // console.log('create registerWithEmail');
        this.sendEmailVerification();
      }).catch(err => {
        reject(err);
      });
    });
  }

  public loginWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
    /*    JSON.stringify(res);  */
        resolve(res);
        // console.log('loginWithEmail');
      }).catch(err => {
        reject(err);
      });
    });
  }

  public sendEmailVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(user => {
        user.sendEmailVerification().then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    });
  }

  // Reset password of the logged in user on Firebase.
  public resetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(email).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut().then((e) => {
        localStorage.setItem('user', null);
        localStorage.setItem('orders', null);
        localStorage.setItem('lastBuy', null);
        this.storage.remove('uid');
        //this.facebook.logout();
        //this.googlePlus.logout();
        this.events.publish('session-start', false);
        // console.log(e);
        this.user = null;
        // console.log('logout() true');
        resolve(true);
      }).catch((e) => {
        // console.log(e);
        // console.log('logout() false');
        reject(e);
      });
    });
  }

}

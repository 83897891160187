import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NavController} from '@ionic/angular';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from 'src/app/services/auth/auth.service';
import {HttpService} from "../../services/http.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  sub1: Subscription;
  private roles: any;
  pass = true;
  found = false;
  settings: any;
  isRestaurant: Boolean = false;

  constructor(
    private auth: AuthService,
    private service: HttpService,
    private navCtrl: NavController,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return new Promise(resolve => {
      if (this.sub1) {
        this.sub1.unsubscribe();
      }
      this.auth.getUser2().then(async userLocal => {
        console.log('user ', userLocal);
        await this.service.sendGET_Settings().subscribe(async (data) => {
          console.log('data -> ', data);
          [this.settings, , , , this.roles] = data;
          console.log('Roles  ', this.roles);

          let url = state.url.slice(1).split('/', 1);
          await this.service.sendGET_Menu().subscribe((data) => {
            for (let i = 0; i < data.length; i++) {
              if (data[i].option_menu_url == url[0] && data[i].onlyRestaurant) {
                if (!this.settings.isRestaurant) {
                  this.navCtrl.navigateRoot('/Dashboard');
                  resolve(false);
                }
                console.log('onlyRestaurant', data[i].onlyRestaurant);
                console.log('isRestaurant', this.settings.isRestaurant);
                // this.pass = (data[i].onlyRestaurant && this.settings.isRestaurant);
              }
            }
          });

          /*this.roles.views.forEach(element => {
            if (element.view_name == state.url.slice(1)) {
              element.users.forEach(user => {
                console.log('user', user);
                if (userLocal.email == user.email) {
                  console.log('encontro el usuario');
                  if (user.permit) {
                    console.log('tiene permisos')
                  } else {
                    console.log('no tiene permisos')
                  }
                  this.found = true;
                }
              });
            }
          });*/
        });


        await console.log('tiene permiso de entrar a este modulo: ', this.pass);
        resolve(true);
        console.log('state url', state.url.slice(1));
        /* if (
          !userLocal.is_super && state.url == '/users' ||
          !userLocal.is_super && state.url == '/locations'
        ) {
          resolve(false);
        } else if (userLocal.is_admin && state.url != '/verification' && !userLocal.is_enabled) {
          resolve(false);
        } else {
          resolve(true);
        } */
      }, (e) => {
        console.log(e);
        this.navCtrl.navigateRoot('/login');
        resolve(false);
      });
    });
  }

}

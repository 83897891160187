import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  appPages: any [] = [];
  imagen: any;
  activeSideBar: any;

  constructor(
    private service: HttpService,
  ) { }

  ngOnInit() {
    this.service.sendGET_SettingsApp().subscribe((data) => {
      // console.log(JSON.stringify(data));
      this.imagen = data.register_screen_commerce_logo;
    });

    this.service.sendGET_Menu().subscribe((data) => {
      // console.log(JSON.stringify(data));
      for (let i = 0; i < data.length; i++) {
        this.appPages.push({
          title: data[i].option_menu_name, 
          url: "/"+data[i].option_menu_name, 
          icon: data[i].option_menu_icon_active,
          icon_inactive: data[i].option_menu_icon_inactive
        });
      }
    });
  }

  buttonsidebar(index){
    this.activeSideBar = index;
  }

}

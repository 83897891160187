import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { User } from 'src/app/models/user';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http.service';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  user = new User();

  constructor(
    private auth: AuthService,
    private service: HttpService,
    private appcomponent: AppComponent,
    private navCtrl: NavController,
  ) { }

  public loadPermits(page){
    return new Promise(async (resolve, reject) => {
      this.auth.getUser2().then(async userLocal => {
        // console.log('User: ', userLocal);
        this.user = userLocal;
        // console.log("loadPermits Page: ",page);
        this.appcomponent.loadRoles(this.user,page).then(permits => {
          // console.log("appcomponent: ",permits);
          if (permits === false) {
            resolve(permits);
            this.navCtrl.navigateRoot('/Dashboard');
            Swal.fire({
              title: `Your user does not have permission to view this page`,
              showDenyButton: false,
              confirmButtonText: 'Ok',
              backdrop: `rgba(0,0,0,0.6) `
            }).then((result) => {
            })
          }else{
            resolve(permits);
          }
        });
      }).catch( e => {
        // console.log('getUser2: ', e);
      });
    })
  }

}

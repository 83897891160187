import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'Dashboard',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'sidebar',
    loadChildren: () => import('./components/sidebar/sidebar.component').then( m => m.SidebarComponent)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./login-signup/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'sign-up-information',
    loadChildren: () => import('./login-signup/sign-up-information/sign-up-information.module').then( m => m.SignUpInformationPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login-signup/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'Orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'Categories',
    loadChildren: () => import('./pages/food/food.module').then( m => m.FoodPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'Products',
    loadChildren: () => import('./pages/food/food.module').then( m => m.FoodPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'Branches',
    loadChildren: () => import('./pages/restaurants/restaurants.module').then( m => m.RestaurantsPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'Business',
    loadChildren: () => import('./pages/restaurants/restaurants.module').then( m => m.RestaurantsPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'Branches-details/:id',
    loadChildren: () => import('./pages/restaurants/restaurants-details/restaurants-details.module').then( m => m.RestaurantsDetailsPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'Invoices',
    loadChildren: () => import('./pages/invoice/invoice.module').then( m => m.InvoicePageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/orders/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'modal-add-product',
    loadChildren: () => import('./pages/orders/modal-add-product/modal-add-product.module').then( m => m.ModalAddProductPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'kitchen',
    loadChildren: () => import('./pages/kitchen/kitchen.module').then( m => m.KitchenPageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'roles',
    loadChildren: () => import('./pages/roles/roles.module').then( m => m.RolesPageModule)
  },
  {
    path: 'subcategory',
    loadChildren: () => import('./pages/subcategory/subcategory.module').then( m => m.SubcategoryPageModule)
  },
  {
    path: 'add-holidays',
    loadChildren: () => import('./pages/modals/add-holidays/add-holidays.module').then( m => m.AddHolidaysPageModule)
  },
  {
    path: 'settings-color',
    loadChildren: () => import('./pages/settings/settings-color/settings-color.module').then( m => m.SettingsColorPageModule)
  },
  {
    path: 'brand',
    loadChildren: () => import('./pages/brand/brand.module').then( m => m.BrandPageModule)
  },
  {
    path: 'supplier',
    loadChildren: () => import('./pages/supplier/supplier.module').then( m => m.SupplierPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

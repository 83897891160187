import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from './../components/header/header.component';
import { NotificationsComponent } from './../components/notifications/notifications.component';
import { AccountComponent } from './../components/account/account.component';
import { SidebarComponent } from './../components/sidebar/sidebar.component';

import { CommonModule } from '@angular/common';
@NgModule({
    declarations:[
        HeaderComponent,
        NotificationsComponent,
        SidebarComponent,
        AccountComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule
    ],
    exports:[
        HeaderComponent,
        NotificationsComponent,
        SidebarComponent,
        AccountComponent,
    ],
})
export class ComponentsModule {}
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { cameraOptions } from '../../../environments/environment';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Storage } from '@ionic/storage';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  profileURL: Promise<any>;
  downloadURL: Observable<string>;

  constructor(
    public file: File,
    public storage: Storage,
    private afStorage: AngularFireStorage,
    private camera: Camera,
  ) {}

  // Clean up the camera
  ngOnDestroy() {
    this.camera.cleanup();
  }

  updatePhotoWeb(collectionName, file,name,id) {
    return new Promise((resolve, reject) => {
      if(file != ''){
        let n = Date.now();
        let file_path = `${collectionName}/${name[0]}_${n}_${id}.${name[1]}`;
        const fileRef = this.afStorage.ref(file_path);
        const task = this.afStorage.upload(`${collectionName}/${name[0]}_${n}_${id}.${name[1]}`, file).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
          const refe = this.afStorage.storage.ref(file_path);

          refe.getDownloadURL().then((url: string) => {
            this.profileURL = snapshot.ref.getDownloadURL();
            resolve({ image: url, file_path });
          }).catch(err => {
            reject(err);
          });

        }).catch(err => {
          reject(err);
        });
      }else{
        resolve({image: '', file_path:''})
      }
    });
  }

  public uploadByWeb (userId: string, event: any) {
    const file = event.target.files[0];
    let fileName = file.name;
    // Append the date string to the file name to make each upload unique.
    fileName = this.appendDateString(fileName);

    const filePath = 'images/' + userId + '/' + fileName;
    const task = this.afStorage.upload(filePath, file);

    return new Promise((resolve, reject) => {
      task.then(snapshot => {
        this.profileURL = snapshot.ref.getDownloadURL();
        resolve(this.profileURL);
      }).catch(err => {
        console.log('Err 1: ', err);
        reject();
      });
    });
  }

  public updatePhoto(collectionName, user_uid, id, file) {
    return new Promise((resolve, reject) => {
      if(file != ''){
        let n = Date.now();
        let file_path = `${collectionName}/${n}_${id}`;
        // const fileRef = this.afStorage.ref(file_path);
        // console.log("fileRef: ",fileRef);
        this.afStorage.upload(file_path, file).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
          const refe = this.afStorage.storage.ref(file_path);

          refe.getDownloadURL().then((url: string) => {
            this.profileURL = snapshot.ref.getDownloadURL();
            resolve({ image: url, file_path });
          }).catch(err => {
            reject(err);
          });

        })
        .catch(err => {
          reject(err);
        });
      }else{
        resolve({image: '', file_path:''})
      }
    });
  }

  // Upload an image file provided the userId, cameraOptions, and sourceType.
  public upload(userId: string, pictureSourceType: number): Promise<string> {

    return new Promise((resolve, reject) => {
      // Set sourceType
      cameraOptions.sourceType = pictureSourceType;
      // Get the image file from camera or photo gallery.
      this.camera.getPicture(cameraOptions).then(fileUri => {

          // Convert URI to Blob
          this.uriToBlob(fileUri).then((blob: Blob) => {
            // Set fileName as the current Date so it will always be unique
            const fileName = Date.now() + '.jpg';
            const filePath = 'images/' + userId + '/' + fileName;

              this.afStorage.upload(filePath, blob).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
                const refe = this.afStorage.storage.ref(filePath);

                refe.getDownloadURL().then((url: string) => {
                  this.profileURL = snapshot.ref.getDownloadURL();
                  resolve(url);
                }).catch(err => {
                  reject(err);
                });

              })
              .catch(err => {
                reject(err);
              });
          }).catch(err => {
            reject();
          });
      });
    });
  }

  // Delete the uploaded file by the user, given the userId and URL of the file.
  public delete(userId: string, url: string): void {
    console.log(userId);
    console.log(url);
    // Get the fileName from the URL.
    const fileName = url.substring(url.lastIndexOf('%2F') + 3, url.lastIndexOf('?'));

    const filePath = 'images/' + userId + '/' + fileName;
    const fileRef = this.afStorage.ref(filePath);

    fileRef.delete();
    // // Check if file really exists on Firebase storage.
    // // this.afStorage.ref(filePath).delete();
    // this.afStorage.ref(filePath).child(userId).then(res => {
    //   res.child().delete();
    // });
  };

  // Convert fileURI to Blob
  public uriToBlob(fileURI): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.file
        .resolveLocalFilesystemUrl(fileURI)
        .then(fileEntry => {
          const { name, nativeURL } = fileEntry;
          const path = nativeURL.substring(0, nativeURL.lastIndexOf('/'));
          return this.file.readAsArrayBuffer(path, name);
        })
        .then((buffer: ArrayBuffer) => {
          let blob = new Blob([buffer], {
            type: 'image/jpeg'
          });
          resolve(blob);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public dataURItoBlob(dataURI): Promise<Blob>  {
    return new Promise((resolve) => {
      const arr = dataURI.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
  
      resolve(new Blob([u8arr], {type: mime}));
    });
  }

  public set(name: string, value: string | boolean) {
    this.storage.set(name, value);
  }

  public get(name: string): Promise<any> | void {
    return new Promise((resolve, reject) => {
      this.storage.get(name).then((val) => {
        resolve(val);
      }).catch(err => {
        reject();
      });
    });
  }

  public keys(): Promise<any> | void {
    return new Promise((resolve, reject) => {
      this.storage.keys().then((val) => {
        resolve(val);
      }).catch(err => {
        reject();
      });
    });
  }

  public remove(name: string): Promise<any> | void {
    return new Promise((resolve, reject) => {
      this.storage.remove(name).then((val) => {
        resolve(val);
      }).catch(err => {
        reject();
      });
    });
  }

  // Append the current date as string to the file name.
  private appendDateString(fileName: string): string {
    const name = fileName.substr(0, fileName.lastIndexOf('.')) + '_' + Date.now();
    const extension = fileName.substr(fileName.lastIndexOf('.'), fileName.length);
    return name + '' + extension;
  }

  setPublicityTime(number){
    this.storage.set('publicity',number);
  }

  getPublicityTime(){
    return new Promise((resolve, rejects) => {
      this.storage.get('publicity').then(value => {
        if (value) {
          resolve(value);
        }else{
          resolve(0);
        }
      });
    });
  }
}

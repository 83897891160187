import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';

import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ComponentsModule } from './components/components.module';
import { File } from '@ionic-native/file/ngx';
import { Storage } from '@ionic/storage';
import { AgmCoreModule } from "@agm/core";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WebView,
    StatusBar,
    SplashScreen,
    Camera,
    File,
    Storage,
    AppComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {Component, Injectable, OnInit} from '@angular/core';
import { Route, Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  isLoggedIn = false;

  constructor(
    private auth: AuthService,
    private alert: AlertController,
    private route: Router,
    private popoverController: PopoverController,
  ) { }

  ngOnInit() {}

  async askToSignOut() {
    const alert = await this.alert.create({
      header: 'Do you want to log out?',

      message: '',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.SignOut();
          }
        }
      ]
    });
    await alert.present();
  }

  SignOut() {
    this.auth.logout().then((e) => {
      this.popoverController.dismiss();
      console.log(e);
      console.log('SignOut()');
      this.isLoggedIn = false;
      //EDITED BY LEO 8-10-2022
      this.route.navigate(['/login']).then( () => window.location.reload());
    }).catch((e) => {console.log(e);});
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppNotifications} from '../models/mail';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  getDatos: any;
  // credenciales
  urlBase = environment.urlBase;
  apikey = environment.apikey;

  constructor(
    private http: HttpClient
  ) {
  }

  sendGET_SettingsApp() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/RegisterScreen/' + this.apikey + '/1/1', {headers});
  }

  sendGET_Splashscreen() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/SplashScreen/' + this.apikey + '/1/1', {headers});
  }

  sendGET_OneSettingsApp(id) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/' + id + '/' + this.apikey + '/1/1', {headers});
  }

  sendGET_Menu() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/AdminMenu/q2d1GFsX93H1NMAT4MXo/' + this.apikey + '/1/1', {headers});
  }

  sendGET_BusinessPlace() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/BusinessPlace/' + this.apikey + '/1/1', {headers});
  }

  sendGET_BusinessPlace_One(id) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/BusinessPlace/' + id + '/' + this.apikey + '/1/1', {headers});
  }

  async sendPUT_Business(messages, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/BusinessPlace/' + id + '/' + this.apikey + '/1/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  sendPOST_Business(messages) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      return this.getDatos = this.http.post<any>(this.urlBase + '/BusinessPlace/' + this.apikey + '/1/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data)
        }, error => {
          console.log(error);
        });
    })
  }

  sendGET_Orders() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + this.apikey + '/1/1', {headers});
  }

  sendGET_OrdersOne(id) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', {headers});
  }

  sendGET_OrdersSales(status) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Orders/' + this.apikey + '/' + status + '/1/1/1/1/1', {headers});
  }

  sendGET_Home_List_Product() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey + '/0/1', {headers});
  }

  sendGET_Home_List_Product_All() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'qs-limit': "6000",
    });
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey, {headers});
  }

  sendGET_Home_List_Product_By_Page(data) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + this.apikey + '/1/' + data.total_docs_by_collection + '/' + data.num_page + '/' + data.limit + '/' + data.offset + '/' + data.order_by + '/' + data.order, {headers});
  }

  sendGET_One_Product(id) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', {headers});
  }

  sendGET_Category() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/CategoriesApp/1/0', {headers});
  }

  sendGET_One_Category(id) {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/CategoriesApp/' + id + '/1/0', {headers});
  }

  getOneUser(uid): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Users/' + uid + '/' + this.apikey + '/1/1', {headers});
  }

  getAllUser(): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/Users/' + this.apikey + '/0/1', {headers});
  }

  sendGET_ProductoTop(): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/ProductsTop/' + this.apikey + '/1/1', {headers});
  }

  sendGET_HelpHeads(): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/HelpContent/heads/' + this.apikey, {headers});
  }

  sendGET_HelpContents(id): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/HelpContent/' + id + '/heads/contents/' + this.apikey, {headers});
  }

  async sendPOST_Category(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // console.log("http: ", raw);
    return this.getDatos = this.http.post<any>(this.urlBase + '/Categories/' + this.apikey + '/0/1', raw, {headers}).subscribe(data => {
      console.log(data['_body']);
    }, error => {
      console.log(error);
    });
  }

  async sendPUT_Category(messages, id) {
    return new Promise((resolve, reject) => {
      var raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/Categories/' + id + '/' + this.apikey + '/0/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  sendPUT_Orders(messages, id, total_update) {
    var raw = JSON.stringify({
      items: messages,
      badgeColor: "warning",
      orders_status_uid: "Modified",
      total: Number(total_update)
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log("http: ", raw);

    return this.getDatos = this.http.put<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', raw, {headers})
      .subscribe(data => {
        console.log(data['_body']);
      }, error => {
        console.log(error);
      });
  }

  sendPUT_Orders_Accept(messages, id) {
    return new Promise((resolve, reject) => {
      var raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/Orders/' + id + '/' + this.apikey + '/1/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data);

        }, error => {
          reject(error);
          console.log(error);
        });
    });
  }

  sendPUT_Product(messages, id) {
    return new Promise((resolve, reject) => {
      var raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  async sendDELETE_Product(id) {
    var raw = JSON.stringify({product_active: false});
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // console.log("http: ", raw);

    return this.getDatos = this.http.put<any>(this.urlBase + '/Products/' + id + '/' + this.apikey + '/0/1', raw, {headers})
      .subscribe(data => {
        console.log(data['_body']);
      }, error => {
        console.log(error);
      });
  }

  sendPOST_Product(messages) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

       return this.getDatos = this.http.post<any>(this.urlBase + '/Products/' + this.apikey + '/0/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data)
        }, error => {
          console.log(error);
        });
    })
  }

  async sendPOST_AppNotifications(messages) {
    console.log("http: ", messages);
    var raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<AppNotifications>(this.urlBase + '/AppNotifications/' + this.apikey + '', raw, {headers})
      .subscribe(data => {
        console.log(data);
      }, error => {
        console.log(error);
      });
  }

  sendGET_Settings() {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + '/SettingsApp/' + this.apikey + '/1/1', {headers});
  }

  async sendPUT_Settings(messages, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(messages);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      console.log("http: ", raw);

      this.getDatos = this.http.put<any>(this.urlBase + '/SettingsApp/' + id + '/' + this.apikey + '/0/1', raw, {headers})
        .subscribe(data => {
          console.log(data);
          resolve(data);
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  getOrdersByStatus(status, orderBy, quantity): Observable<any> {
    const headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + `/OrderStatus/${this.apikey}/status/${status}/${orderBy}/${quantity}`, {headers});
  }

  sendGET_subcategory(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/SubCategories/${orderField}/${orderBy}`, {headers});
  }

  sendGET_brand(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Brands/${orderField}/${orderBy}`, {headers});
  }

  sendGET_supplier(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Suppliers/${orderField}/${orderBy}`, {headers});
  }

  async sendPOST_Subcategory(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/SubCategories', raw, {headers}).subscribe(data => {
      console.log(data['_body']);
    }, error => {
      console.log(error);
    });
  }

  async sendPUT_subcategory2(message_post, id_subcategory) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/SubCategories/${id_subcategory}/666`, raw, {headers}).subscribe(data => {
        console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        console.log(error);
      });
    })
  }

  async sendPUT_subcategory(message_post, id_subcategory) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/SubCategories/${id_subcategory}`, raw, {headers}).subscribe(data => {
        console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        console.log(error);
      });
    })
  }

  getSubcategory(id) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/SubCategories/${id}`, {headers});
  }

  async sendPOST_Brand(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/Brands', raw, {headers}).subscribe(data => {
      console.log(data['_body']);
    }, error => {
      console.log(error);
    });
  }

  async sendPUT_Brand(message_post, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Brands/${id}`, raw, {headers}).subscribe(data => {
        console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        console.log(error);
      });
    })

  }

  getBrand(id) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Brands/${id}`, {headers});
  }

  async sendPOST_Supplier(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/Suppliers', raw, {headers}).subscribe(data => {
      console.log(data['_body']);
    }, error => {
      console.log(error);
    });
  }

  async sendPUT_Supplier(message_post, id) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Suppliers/${id}`, raw, {headers}).subscribe(data => {
        console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        console.log(error);
      });
    })

  }

  getSupplier(id) {
    let headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Suppliers/${id}`, {headers});
  }

  getProductsByCategory(id, total_docs, num_page, limit, num_docs_page) {
    let headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>(this.urlBase + `/Products/${this.apikey}/1/${id}/${total_docs}/${num_page}/${limit}/${num_docs_page}/product_name/asc`, {headers});
  }

  async sendPOST_Classifications(messages) {
    let raw = JSON.stringify(messages);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.post<any>(this.urlBase + '/Classifications', raw, {headers}).subscribe(data => {
      console.log(data['_body']);
    }, error => {
      console.log(error);
    });
  }

  sendGET_Classifications(orderField, orderBy) {
    const headers = new HttpHeaders({
      'api-key': this.apikey,
    });
    return this.getDatos = this.http.get<any>(this.urlBase + `/Classifications/${orderField}/${orderBy}`, {headers});
  }

  async sendPUT_Classifications2(message_post, id_clasification) {
    return new Promise((resolve, reject) => {
      let raw = JSON.stringify(message_post);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': this.apikey,
      });
      return this.getDatos = this.http.put<any>(this.urlBase + `/Classifications/${id_clasification}/666`, raw, {headers}).subscribe(data => {
        console.log(data['_body']);
        resolve(data['_body']);
      }, error => {
        reject(error);
        console.log(error);
      });
    })
  }

  get_products(){
    let headers = new HttpHeaders();
    return this.getDatos = this.http.get<any>('https://store.centralhidraulica.com/migrate-products', {headers});
  }
}

import { Injectable } from '@angular/core';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  audio: any;
  intervalSound: any;

  constructor(
    public events: EventsService,
  ) {
    this.events.subscribe('alert:sound', (data) => {
      // console.log("alert:sound: ",data)
      if (data['sound']) { this.initSound(); }
      else { this.stopSound(); }
    });
    if(this.audio){ this.audio = null;}
    this.audio = new Audio();
    this.audio.src = './../../assets/audio/hangouts_message.mp3';
    this.audio.load();
   }

   initSound() {
    let this_ = this;
    this.audio.play();
    this.audio.loop = true;
    
    clearInterval(this.intervalSound);
    this.intervalSound = setInterval(function () {
      //console.log('event!!!')
      this_.events.publish('alert:changeColor', { change: true });
    }, 1000);
  }
  stopSound() {
    if (this.audio) {
      this.audio.pause();
      //this.audio = null;
    }
    clearInterval(this.intervalSound);
  }
}
